import React from 'react';
import profilePic from '../assets/profile-pic.jpg';
import { rhythm } from '../utils/typography';

class Bio extends React.Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          marginBottom: rhythm(2),
        }}
      >
        <img
          src={profilePic}
          alt={`Jeremy Bensing`}
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            width: rhythm(3),
            height: rhythm(3),
            borderRadius: '50%',
          }}
        />
        <p style={{ marginTop: rhythm(1 / 2) }}>
          Personal site of{' '}
          <a href="https://mobile.twitter.com/jtbensing">Jeremy Bensing</a>.
          <br />
          NYC Based Software Developer working at{' '}
          <a href="https://mongodb.com">MongoDB</a>
        </p>
      </div>
    );
  }
}

export default Bio;
