import { Link, graphql } from 'gatsby';
import { formatPostDate, formatReadingTime } from '../utils/helpers';

import Bio from '../components/Bio';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import React from 'react';
import SEO from '../components/SEO';
import get from 'lodash/get';
import { rhythm } from '../utils/typography';

const ComingSoonLink = ({ node, title }) => (
  <Link
    style={{ boxShadow: 'none' }}
    to={node.fields.slug}
    rel="bookmark"
    onClick={e => e.preventDefault()}
  >
    Coming Soon - {title}
  </Link>
);

class BlogIndexTemplate extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const langKey = this.props.pageContext.langKey;

    const posts = get(this, 'props.data.allMarkdownRemark.edges');

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO />
        <aside>
          <Bio />
        </aside>
        <main>
          <div
            style={{
              fontFamily: 'Montserrat, sans-serif',
              color: 'var(--textLink)',
              fontSize: rhythm(1),
            }}
          >
            <p>Hi.</p>
            <p style={{ color: 'var(--textNormal)' }}>
              My name is Jeremy Bensing. This is my personal site that might
              have content one day.
            </p>
          </div>
          {posts &&
            posts.map(({ node }) => {
              const title = get(node, 'frontmatter.title') || node.fields.slug;
              const isPublic = !get(node, 'frontmatter.private');
              return (
                isPublic && (
                  <article key={node.fields.slug}>
                    <header>
                      <h3
                        style={{
                          fontFamily: 'Montserrat, sans-serif',
                          fontSize: rhythm(1),
                          marginBottom: rhythm(1 / 4),
                        }}
                      >
                        <Link
                          style={{ boxShadow: 'none' }}
                          to={node.fields.slug}
                          rel="bookmark"
                        >
                          {title}
                        </Link>
                        <small>
                          {formatPostDate(node.frontmatter.date, langKey)}
                        </small>
                      </h3>
                    </header>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: node.frontmatter.spoiler,
                      }}
                    />
                  </article>
                )
              );
            })}
        </main>
        <Footer />
      </Layout>
    );
  }
}

export default BlogIndexTemplate;

export const pageQuery = graphql`
  query($langKey: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            langKey
          }
          timeToRead
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            spoiler
            private
          }
        }
      }
    }
  }
`;
